<template>
    <div class="datafeed-content">
        <div class="datafeed-content__center">
            <div class="datafeed-content__center__inner">
                <h2 class="mb-4 text-xl font-bold text-center">Register</h2>
                <RegisterForm class="p-5 bg-white border rounded shadow"/>
            </div>
        </div>
    </div>
</template>

<script>
    import RegisterForm from "@/components/RegisterForm";

    export default {
        name: "RegisterView",
        components: {
            RegisterForm,
        },
    };
</script>
